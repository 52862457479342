/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var menuList, mobileToggle;
        mobileToggle = $('.header__logo > .nav-1');
        mobileClose = $('.nav-primary > .close-icon');
        menuList = $('.nav-primary');

        mobileToggle.on("click", function (e) {
          e.preventDefault();
          menuList.slideToggle();
        });

        mobileClose.on("click", function (e) {
          e.preventDefault();
          menuList.slideToggle();
        });

        $('.testimonials__slider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerMode: true,
          // variableWidth: true,
          arrows: false,
          dots: false,
          pauseOnFocus: true,
          pauseOnHover: true,
          // autoplay: true,
          // autoplaySpeed: 3000,
          centerPadding: '0px',
          fade: true
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
        function locationControl() {
          if ($('#location_state').is(':visible')) {
            $('#location_conyers').hide();
            $('#conyers_pin').css('fill', '#fff');
            $('#augusta_pin').css('fill', '#faa035');
            $('#conyers_pin').click(function () {
              $('#location_conyers').show();
              $('#location_augusta').hide();
              $('#conyers_pin').css('fill', '#faa035');
              $('#augusta_pin').css('fill', '#fff');
            });
            $('#augusta_pin').click(function () {
              $('#location_conyers').hide();
              $('#location_augusta').show();
              $('#augusta_pin').css('fill', '#faa035');
              $('#conyers_pin').css('fill', '#fff');
            });
          } else {
            $('#location_conyers').show();
            $('#location_augusta').show();
          }
        }
        locationControl()
        $(window).resize(function () {
          locationControl();
        });

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');

      AOS.init();

      $(window).on('load', function () {
        AOS.refresh();
      });
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
